async function GpayCalling(amount, merchantId, merchantName, environment, gateway, gatewayMerchantId) {
  let gpay_amount = amount;
  let gpay_merchantId = merchantId;
  let gpay_merchantName = merchantName;
  let gpay_environment = environment;
  let gpay_gateway = gateway;
  let gpay_gatewayMerchantId = gatewayMerchantId;
  window._mfq = window._mfq || [];
  $("#hdnpayment_opts").show();
  if (!isSafari()) {
    $('#apay').hide();
  }
  $('#payment_opts_skeleton').hide();
  function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }
  (function () {
    var mf = document.createElement("script");
    mf.type = "text/javascript";
    mf.async = true;
    mf.src = "https://pay.google.com/gp/p/js/pay.js";
    mf.onload = () => {
      console.log('Google API Script loaded');
      onGooglePayLoaded();
    }
    document.getElementsByTagName("head")[0].appendChild(mf);
  })();
  const baseRequest = {
    apiVersion: 2,
    apiVersionMinor: 0
  };
  const allowedCardNetworks = ["AMEX", "JCB", "MASTERCARD", "VISA"];
  const allowedCardAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"];
  const tokenizationSpecification = {
    type: 'PAYMENT_GATEWAY',
    parameters: {
      "gateway": gpay_gateway ,///'cybersource',
      "gatewayMerchantId": gpay_gatewayMerchantId///'moresand_test_sit'
    }
  };
  const baseCardPaymentMethod = {
    type: 'CARD',
    parameters: {
      allowedAuthMethods: allowedCardAuthMethods,
      allowedCardNetworks: allowedCardNetworks,
      assuranceDetailsRequired: true,
      billingAddressRequired: true,
      billingAddressParameters: {
        format: 'FULL',
        phoneNumberRequired: true
      }
    }
  };
  const cardPaymentMethod = Object.assign(
    {},
    baseCardPaymentMethod,
    {
      tokenizationSpecification: tokenizationSpecification
    }
  );
  let paymentsClient = null;
  function getGoogleIsReadyToPayRequest() {
    return Object.assign(
      {},
      baseRequest,
      {
        allowedPaymentMethods: [baseCardPaymentMethod]
      }
    );
  }
  function getGooglePaymentDataRequest() {
    const paymentDataRequest = Object.assign({}, baseRequest);
    paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
    paymentDataRequest.emailRequired = true;
    paymentDataRequest.transactionInfo = getGoogleTransactionInfo();
    paymentDataRequest.merchantInfo= {
      merchantId: gpay_merchantId,///'12345678901234567890', // Your unique merchant ID
      merchantName: gpay_merchantName///'Example Merchant' // Your merchant name
    };
    return paymentDataRequest;
  }
  function getGooglePaymentsClient() {
    if (paymentsClient === null) {
      paymentsClient = new google.payments.api.PaymentsClient({ environment: gpay_environment });
    }
    return paymentsClient;
  }
  function onGooglePayLoaded() {
    const paymentsClient = getGooglePaymentsClient();
    paymentsClient.isReadyToPay(getGoogleIsReadyToPayRequest())
      .then(function (response) {
        if (response.result) {
          addGooglePayButton();
          //prefetch payment data to improve performance after confirming site functionality
          prefetchGooglePaymentData();
        }
      })
      .catch(function (err) {
        // show error in developer console for debugging
        console.error(err);
      });
  }
  function addGooglePayButton() {
    const paymentsClient = getGooglePaymentsClient();
    const button =
      paymentsClient.createButton({
        buttonColor: 'default',
        buttonType: 'pay',
        onClick: onGooglePaymentButtonClicked,
        allowedPaymentMethods: [baseCardPaymentMethod]
      });
    document.getElementById('google-pay-container').appendChild(button);
  }
  function getGoogleTransactionInfo() {
    const amount = gpay_amount;// document.getElementById('amount').value;
    return {
      countryCode: 'GB',
      currencyCode: 'GBP',
      totalPriceStatus: 'FINAL',
      // set to cart total
      totalPrice: amount.at(-1) === "." ? amount.split(".")[0] : amount,
      checkoutOption: "COMPLETE_IMMEDIATE_PURCHASE"
    };
  }
  function prefetchGooglePaymentData() {
    const paymentDataRequest = getGooglePaymentDataRequest();
    // transactionInfo must be set but does not affect cache
    paymentDataRequest.transactionInfo.totalPrice = '1.00';
    const paymentsClient = getGooglePaymentsClient();
    paymentsClient.prefetchPaymentData(paymentDataRequest);
  }
  function setGooglePayHiddenInputs(paymentData) {
    let digitalWalletPayload = document.getElementById("digitalWalletPayload");
    let digitalWallet = document.getElementById("digitalWallet");
    const paymentPayload = JSON.stringify(paymentData);
    digitalWalletPayload.value = paymentPayload;
    digitalWallet.value = "GooglePay";
  }
  function onGooglePaymentButtonClicked() {
    //if (!document.getElementById("PaymentForms").valid()) return;
    //if (!$("#PaymentForms").valid()) return;
    const paymentDataRequest = getGooglePaymentDataRequest();
    paymentDataRequest.transactionInfo = getGoogleTransactionInfo();
    const paymentsClient = getGooglePaymentsClient();
    paymentsClient.loadPaymentData(paymentDataRequest)
      .then(function (paymentData) {
        // handle the response
        setGooglePayHiddenInputs(paymentData);
        //$("#paymentdetails").submit();
        //document.getElementById("paymentSubmit").submit();
        document.getElementById("paymentSubmit").click();
        //gpaySubmit();
      })
      .catch(function (err) {
        // show error in developer console for debugging
        console.error(err);
      });
  }
}
async function ApplePayCalling(amount, MerchantIdentifier) {
  let amountdata = amount;
  let apay_MerchantIdentifier = MerchantIdentifier;
  window._mfq = window._mfq || [];
  //(function () {
  //  var mf = document.createElement("script");
  //  mf.type = "text/javascript";
  //  mf.async = true;
  //  mfapple.crossorigin = "anonymous";
  //  mf.src   = "https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js";
  //    console.log('Apple API Script loaded');


  //   // onGooglePayLoaded();

  //  document.getElementsByTagName("head")[0].appendChild(mf);
  //})();
  const applePayButton = document.createElement('apple-pay-button');
  // Set the attributes as per your requirements
  applePayButton.setAttribute('id', 'apple-pay-button');
  applePayButton.setAttribute('buttonstyle', 'black');
  applePayButton.setAttribute('value', 'continue');
  applePayButton.setAttribute('locale', 'en-GB');
  // Append the button to the target section
  document.getElementById('apple-section').appendChild(applePayButton);
  
  // Define allowed card networks and authentication methods
  const AppleAllowedCardNetworks = ['amex', 'jcb', 'masterCard', 'visa', 'discover'];
  const AppleAllowedCardAuthMethods = ['supports3DS'];


  

  function isIncognito() {
    return new Promise((resolve) => {
      try {
        const fs = window.RequestFileSystem || window.webkitRequestFileSystem;
        if (!fs) {
          resolve(false);
          return;
        }
        fs(window.TEMPORARY, 100, () => resolve(false), () => resolve(true));
      } catch (e) {
        resolve(true);
      }
    }).catch(() => true);
  }

  document.addEventListener("DOMContentLoaded", function () {
    // Check if Apple Pay is available and if an active card is set up
    if (window.ApplePaySession) {
      console.log('Apple API Script loaded for ApplePaySession');
      const merchantIdentifier = apay_MerchantIdentifier;
      const promise = ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier);
      promise.then(function (canMakePayments) {
        if (canMakePayments) {
          $('#apay').show();
          /*$('#setup-apple-pay').hide();*/ // Hide the setup button if Apple Pay is already set up
        } else {
          // Check if the setup method exists and prompt user to set up Apple Pay
          if (ApplePaySession.openPaymentSetup) {
            $('#setup-apple-pay').show(); // Show the setup button
            $('#setup-apple-pay').text('Set up Apple Pay'); // Set button text

            // Add an event listener for the setup button click
            $('#setup-apple-pay').on('click', function () {
              ApplePaySession.openPaymentSetup(merchantIdentifier)
                .then(function (success) {
                  if (success) {
                    // Handle successful setup
                    /*$('#setup-apple-pay').hide();*/ // Hide the setup button after successful setup
                  } else {
                    // Handle setup failure
                    console.log('Setup failed');
                  }
                })
                .catch(function (e) {
                  // Handle setup error
                  console.error("Error opening payment setup:", e);
                  //$('#apple-pay-error').removeClass('displayblock');
                  //$('#apple-pay-error').show();
                });
            });
          } else {
            console.log('Apple Pay setup is not available.');
          }
        }
      });
    }
    else {
      $('#apay').hide();
    }


  });

  //Handle Apple Pay button click
  document.getElementById('apple-pay-button').addEventListener('click', function () {
    //if (!$("#paymentdetails").valid()) return;

    //let allowPayment = true;
    //if ($.trim($("#BookingRef").val()) == "") {
    //  allowPayment = false;
    //  $('.btextvalidation').text("Please enter your booking ref.");
    //  $("#BookingRef").addClass("input-validation-error");
    //  $('#BookingRef').focus();
    //}

    //if ($("#Amount").val() == "") {
    //  allowPayment = false;
    //}
    //if (isNaN(parseFloat($("#Amount").val()))) {
    //  $(".Amount").text("Please enter valid amount");
    //  $("#Amount").addClass("input-validation-error");
    //  $('#Amount').focus();
    //  allowPayment = false;
    //}
    //else if (parseFloat($("#Amount").val()) < 1) {
    //  $(".Amount").text("Please enter valid amount");
    //  $("#Amount").addClass("input-validation-error");
    //  $('#Amount').focus();
    //  allowPayment = false;
    //}

   
    const amount = amountdata;/// document.getElementById('Amount').value;

      const paymentRequest = {
        countryCode: 'GB',
        currencyCode: 'GBP',
        merchantCapabilities: AppleAllowedCardAuthMethods,
        supportedNetworks: AppleAllowedCardNetworks,
        total: {
          label: "MOREROOMS",
          amount: amount
        },
        requiredBillingContactFields: ["email", "name", "phone", "postalAddress"],
        requiredShippingContactFields: ["email", "name", "phone", "postalAddress"]
      };

      const session = new ApplePaySession(3, paymentRequest);

      // Handle merchant validation
      session.onvalidatemerchant = event => {
        const data = { validationUrl: event.validationURL };
        $.ajax({
          url: '/ApplePay/ValidateAppleMerchant',
          type: 'POST',
          data: data
        })
          .done(merchantSessionString => {
            try {
              const merchantSession = JSON.parse(merchantSessionString);

              if (!('merchantIdentifier' in merchantSession &&
                'merchantSessionIdentifier' in merchantSession &&
                ('nOnce' in merchantSession || 'nonce' in merchantSession))) {
                alert("Invalid merchant session");
                throw new Error('Invalid merchant session');
              }

              session.completeMerchantValidation(merchantSession);

            } catch (e) {
              console.error("Merchant validation failed:", e);
              alert("Merchant validation failed:");
              session.abort();
              //$('#apple-pay-error').removeClass('displayblock');
              //$('#apple-pay-error').show();
            }
          })
          .fail((jqXHR, textStatus, errorThrown) => {
            console.error("Merchant validation request failed:", textStatus, errorThrown);
            alert("Merchant validation request failed:");
            session.abort();
            //$('#apple-pay-error').removeClass('displayblock');
            //$('#apple-pay-error').show();
          });
      };

      // Handle payment method selection
      session.onpaymentmethodselected = event => {
        const update = {
          newTotal: {
            amount: amountdata,
            label: "MOREROOMS"
          }
        };
        session.completePaymentMethodSelection(update);
      };

      // Handle shipping method selection
      session.onshippingmethodselected = event => {
        const update = { newTotal: {} };
        session.completeShippingMethodSelection(update);
      };

      // Handle payment cancellation
      session.oncancel = event => {
        if (session !== null) {
          try {
            session.abort();
          } catch {
            // do nothing
          }

        }
        console.log("Payment canceled");
     /*   $('#apple-pay-error').removeClass('displayblock');*/
      };

      // Handle coupon code change
      session.oncouponcodechanged = event => {
        const update = { newTotal: {} };
        session.completeCouponCodeChange(update);
      };

      // Handle shipping contact selection
      session.onshippingcontactselected = event => {
        const update = {
          newTotal: {
            amount: amountdata,
            label: "MOREROOMS"
          }
        };
        session.completeShippingContactSelection(update);
      };

      // Handle payment authorization
      session.onpaymentauthorized = event => {
        const paymentPayload = JSON.stringify(event.payment);

        // Remove previous hidden inputs
        //$('input[type="hidden"][name="digitalWalletPayload"]').remove();
        //$('input[type="hidden"][name="digitalWallet"]').remove();

        // Create and append new hidden inputs
        const payloadInput = document.getElementById("digitalWalletPayload");
        //payloadInput.id = "digitalWalletPayload";
        //payloadInput.type = "hidden";
        //payloadInput.name = "digitalWalletPayload";
        payloadInput.value = paymentPayload;
        payloadInput.classList.add("apay-only-field");
        console.log(payloadInput);
        const paymentMethodInput = document.getElementById("digitalWallet");
        //paymentMethodInput.id = "digitalWallet";
        //paymentMethodInput.type = "hidden";
        //paymentMethodInput.name = "digitalWallet";
        paymentMethodInput.value = "ApplePayMerchantDecryption";
        paymentMethodInput.classList.add("apay-only-field");

        document.getElementById("apple-pay-button").appendChild(payloadInput);
        document.getElementById("apple-pay-button").appendChild(paymentMethodInput);
        console.log(paymentMethodInput);
        // Complete the payment
        const result = { status: ApplePaySession.STATUS_SUCCESS };
        session.completePayment(result);

        // Submit the payment data
        document.getElementById("paymentSubmit").click();

      };

      // Begin the Apple Pay session
      session.begin();
    
  });
}
var mf = document.createElement("script");
mf.type = "text/javascript";
mf.async = true;
mf.src = "https://pay.google.com/gp/p/js/pay.js";
document.getElementsByTagName("head")[0].appendChild(mf);
var mfapple = document.createElement("script");
mfapple.type = "text/javascript";
mfapple.async = true;
mfapple.crossorigin = "anonymous";
mfapple.src = "https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js";
document.getElementsByTagName("head")[0].appendChild(mfapple);



